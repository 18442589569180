<template>
  <div class="not-found">
    <img src="../assets/images/Mercala_logo_red.svg" alt="Mercala Logo" />

    <h1>Success!</h1>

    <p>We got your payment and you have a new subscription!</p>

    <p>You will be redirected to the website in a moment.</p>
  </div>
</template>

<script>
export default {
  name: "PaymentSuccess",
  mounted() {
    setTimeout(() => {
      this.$router.replace("/");
    }, 5000);
  },
};
</script>

<style lang="scss">
.not-found {
  text-align: center;

  h1 {
    font-weight: 600;
    margin-bottom: 25px;
    color: #ff1e1e;
    font-size: 2.25rem;
  }

  p {
    line-height: 1.5rem;
    color: black;
    font-size: 1rem;
    margin-bottom: 25px;
  }

  img {
    width: 600px;
    margin: 50px auto 25px auto;
  }
}
</style>
