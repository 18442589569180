<template>
  <div class="not-found">
    <img src="../assets/images/Mercala_logo_red.svg" alt="Mercala Logo" />

    <h1>Help! 404!</h1>

    <p>
        We got your 404 signal!
    </p>

    <p>
        It seems that you have lost your way around the Caribbean waters.
    </p>

    <p>
        But don't worry, you can find your way back by clicking the button below!
    </p>

    <MercalaButton 
        v-on:button-event="toHome()"
        label="Let's head on back home!"
        type="ok" />

  </div>
</template>

<script>
import MercalaButton from '@/components/form/MercalaButton'
export default {
  name: 'NotFound',
  components: {
    MercalaButton
  },
  methods: {
    toHome(){
      this.$router.replace('/');
    }
  }
}
</script>

<style lang="scss">

.not-found{
    text-align: center;

    h1{
        font-weight: 600;
        margin-bottom: 25px;
        color: #FF1E1E;
        font-size: 2.25rem;
    }

    p{
        line-height: 1.5rem;
        color: black;
        font-size: 1rem;
        margin-bottom: 25px;
    }
  
    img{
        width: 600px;
        margin: 50px auto 25px auto;
    }
}

</style>
