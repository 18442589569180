<template>
  <div @click="mainClickEvent" class="dashboard">
    <NotificationPopup ref="NotificationPopup" />

    <SaveFilterModal
      v-if="showFilterModal"
      v-on:close-modal="toggleModal(false)"
    />

    <Lightbox
      v-if="showLightbox"
      :lightbox="lightboxData"
      v-on:logout="signOut"
    />

    <div class="dashboard-header">
      <img
        src="../assets/images/Mercala_logo_red.svg"
        class="logo"
        alt="Mercala Logo"
      />

      <div :class="profileIconClasses()">
        <p>{{ user.email }}</p>
        <div @click="openProfileTools()" :class="profilePictureClasses()"></div>
        <div :class="profileToolClasses()">
          <ul>
            <li>
              <a href="javascript:void(0);" @click="openProfileEdit()"
                >Edit Profile</a
              >
            </li>
            <li>
              <a href="javascript:void(0);" @click="signOut()">Log out</a>
            </li>
          </ul>
        </div>
      </div>

      <div v-on:click="sideFilterToggle()" :class="filterToggleClasses()"></div>
    </div>

    <div class="dashboard-navigation">
      <div
        v-for="(item, index) in navigationItems"
        :key="index"
        :class="navigationItemClasses(item)"
        v-on:click="changeView(item)"
      ></div>

      <div
        class="dashboard-navigation-item dashboard-navigation-item-logout"
        v-on:click="signOut()"
      ></div>
    </div>

    <div :class="dashboardContentClasses()">
      <HousingList v-if="view === 'listings'" />
      <FilterManagement v-if="view === 'filters'" />
      <Statistics v-if="view === 'statistics'" />
      <UserList v-if="view === 'admin-user'" />
      <DashboardSettings
        v-if="view === 'admin-dashboard-settings'"
        v-on:open-lightbox="openLightbox"
      />
      <FormPrototype v-if="view === 'admin-form-prototype'" />
    </div>

    <div :class="dashboardFilterClasses()">
      <SideFilter
        v-on:open-modal="toggleModal(true)"
        v-on:close-modal="toggleModal(false)"
      />
    </div>
  </div>
</template>

<script>
import AngelaAPI from "@/utils/AngelaAPI";
import { EventBus } from "@/utils/EventBus.js";
import { Lightboxes } from "@/utils/Constants";

export default {
  name: "Home",
  components: {
    HousingList: () => import("@/components/dashboard/housing/HousingList"),
    FilterManagement: () =>
      import("@/components/dashboard/filters/FilterManagement"),
    SaveFilterModal: () =>
      import("@/components/dashboard/housing/SaveFilterModal"),
    SideFilter: () => import("@/components/dashboard/housing/SideFilter"),
    Statistics: () => import("@/components/dashboard/statistics/home"),
    NotificationPopup: () => import("@/components/dashboard/NotificationPopup"),
    UserList: () => import("@/components/dashboard/admin_users/UserList"),
    DashboardSettings: () =>
      import("@/components/dashboard/admin_settings/DashboardSettings"),
    Lightbox: () => import("@/components/lightboxes/Lightbox"),
    FormPrototype: () =>
      import("@/components/dashboard/form_prototype/FormPrototype"),
  },
  data() {
    return {
      view: "listings",
      showProfileTools: false,
      showSideFilter: true,
      showFilterModal: false,
      showLightbox: false,
      lightboxData: {},
      user: {
        email: "",
      },
      navigationItems: ["listings", "filters"],
    };
  },
  watch: {
    $route(to, from) {
      this.showSideFilter = to.params.page === "listings";
      this.view = to.params.page;
    },
  },
  methods: {
    openProfileTools() {
      this.showProfileTools = !this.showProfileTools;
    },
    openLightbox(lightboxData) {
      this.lightboxData = lightboxData;
      this.showLightbox = true;
    },
    closeLightbox() {
      this.showLightbox = false;
    },
    changeView(view) {
      this.$router.push({ name: "Dashboard", params: { page: view } });
    },
    sideFilterToggle() {
      if (this.showSideFilter) {
        this.showSideFilter = false;
      } else {
        this.showSideFilter = true;
      }
    },
    signOut() {
      AngelaAPI.logout()
        .then((response) => {
          this.$store.commit("setAuthenticationToken", null);
          this.$router.push("/");
        })
        .catch((error) => {
          // Nothing to handle
        });
    },
    toggleModal(state) {
      this.showFilterModal = state;
    },

    overduePreNotice() {
      if (
        this.user.subscription_type === "standard" &&
        this.user.subscription_remaining_days >= 0 &&
        this.user.subscription_remaining_days <= 7
      ) {
        setTimeout(() => {
          let expirationText = "today";
          if (this.user.subscription_remaining_days === 1) {
            expirationText = "in 1 day";
          }
          if (this.user.subscription_remaining_days > 1) {
            expirationText = `in ${this.user.subscription_remaining_days} days`;
          }

          let title = "Subscription ending soon";
          let message = `Your subscription expires ${expirationText}. Contact sales to renew your subscription.`;
          if (this.user.subscription_status === "pending") {
            title = "Unpaid Subscription";
            message = `Your current subscription is not paid yet and expires ${expirationText}. Please pay for your subscription in order to keep using the Mercala dashboard.`;
          }

          EventBus.$emit("show-notification", {
            title: title,
            message: message,
            success: false,
            milliseconds: 120000,
          });
        }, 3000);
      }
    },

    noAccessCheck() {
      if (
        this.user.subscription_status === "overdue" ||
        this.user.subscription_status === "expired"
      ) {
        this.openLightbox({
          view: Lightboxes.Expired,
          data: {
            status: this.user.subscription_status,
            subscriptionType: this.user.subscription_type,
          },
        });
      }
    },

    checkAgreements() {
      if (!this.user.agreements_accepted) {
        this.lightboxData = {
          view: Lightboxes.Agreements,
          data: {
            terms: "termsOfUse",
            refreshText: true,
            showButtons: true,
          },
        };
        this.showLightbox = true;
      }
    },
    openProfileEdit() {
      this.lightboxData = {
        view: Lightboxes.UserProfileEdit,
        data: {},
      };
      this.showLightbox = true;
      this.showProfileTools = false;
    },
    mainClickEvent(event) {
      if (this.showProfileTools) {
        // Check if within the tools element
        const toolsElement = event.path.find((item) => {
          return item.className === "dashboard-header-profile-tools open";
        });

        const iconElement = event.path.find((item) => {
          return (
            item.className === "dashboard-header-profile-picture" ||
            item.className === "dashboard-header-profile-picture tools-open"
          );
        });

        this.showProfileTools = toolsElement || iconElement;
      }
    },

    // Classes
    profilePictureClasses() {
      return {
        "dashboard-header-profile-picture": true,
        "tools-open": this.showProfileTools,
      };
    },
    profileToolClasses() {
      return {
        "dashboard-header-profile-tools": true,
        open: this.showProfileTools,
        closed: !this.showProfileTools,
      };
    },
    navigationItemClasses(navView) {
      const classes = {
        "dashboard-navigation-item": true,
        "dashboard-navigation-item-active": navView === this.view,
      };
      classes[`dashboard-navigation-item-${navView}`] = true;
      return classes;
    },
    dashboardContentClasses() {
      return {
        "dashboard-content": true,
        "dashboard-content-filter-opened": this.showSideFilter,
      };
    },
    dashboardFilterClasses() {
      return {
        "dashboard-filter": true,
        "dashboard-filter-filter-opened": this.showSideFilter,
      };
    },
    profileIconClasses() {
      return {
        "dashboard-header-profile": true,
        "dashboard-header-profile-filter-opened": this.showSideFilter,
      };
    },
    filterToggleClasses() {
      return {
        "dashboard-header-filter-toggle": true,
        "dashboard-header-filter-toggle-filter-opened": this.showSideFilter,
        "dashboard-header-filter-toggle-viewfilter-active":
          this.view !== "listings",
      };
    },
  },
  mounted() {
    // General Access
    this.showSideFilter = this.$route.params.page === "listings";
    this.view = this.$route.params.page;
    this.user = this.$store.getters.getUserData;
    EventBus.$on("close-side-filter", this.sideFilterToggle);
    EventBus.$on("open-lightbox", this.openLightbox);
    EventBus.$on("close-lightbox", this.closeLightbox);

    // Agreements popup
    this.checkAgreements();

    // Overdue 7 days notice (TODO - Add a payment link or open the packages lightbox)
    // this.overduePreNotice();

    // No Access
    this.noAccessCheck();

    // Admin Access
    if (this.user.status === "admin") {
      this.navigationItems.push("admin-user");
      this.navigationItems.push("admin-dashboard-settings");
      this.navigationItems.push("admin-form-prototype");
    }
  },
  destroyed() {
    EventBus.$off("close-side-filter", this.sideFilterToggle);
    EventBus.$off("open-lightbox", this.openLightbox);
    EventBus.$off("close-lightbox", this.closeLightbox);
  },
};
</script>

<style lang="scss">
@import "../styles/views/dashboard";
</style>
